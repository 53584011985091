import React, { useState, useEffect } from "react"
import { Form, Row, Col } from "react-bootstrap"

const TabGameSettings = ({ game, gameMounted }) => {
  const [idleDropSpeed, setIdleDropSpeed] = useState(0)
  const [softDropSpeed, setSoftDropSpeed] = useState(30)
  const [lockTime, setLockTime] = useState(24247)

  const validateNumber = input => {
    if (!(Number.isNaN(input) || input === "")) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (gameMounted.current) {
      if (validateNumber(idleDropSpeed)) {
        game.current.state.activePair.idleDropDistance = idleDropSpeed
      }
    }
  }, [idleDropSpeed])

  useEffect(() => {
    if (gameMounted.current) {
      if (validateNumber(softDropSpeed)) {
        game.current.state.activePair.softDropDistance = softDropSpeed
      }
    }
  }, [softDropSpeed])

  useEffect(() => {
    if (gameMounted.current) {
      if (validateNumber(lockTime)) {
        game.current.state.activePair.lockTimerLimit = lockTime
      }
    }
  }, [lockTime])

  return (
    <Form>
      <Form.Group as={Row} controlId="idleDropSpeed">
        <Form.Label column sm={6}>
          Idle Drop Speed (px/frame)
        </Form.Label>
        <Col sm={3}>
          <Form.Control
            type="number"
            min="0"
            max="59"
            value={idleDropSpeed}
            onChange={e => {
              if (e.target.value === "") {
                setIdleDropSpeed("")
              } else {
                setIdleDropSpeed(parseInt(e.target.value, 10))
              }
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="softDropSpeed">
        <Form.Label column sm={6}>
          Soft Drop Speed (px/frame)
        </Form.Label>
        <Col sm={3}>
          <Form.Control
            type="number"
            min="0"
            max="59"
            value={softDropSpeed}
            onChange={e => {
              if (e.target.value === "") {
                setSoftDropSpeed("")
              } else {
                setSoftDropSpeed(parseInt(e.target.value, 10))
              }
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="softDropSpeed">
        <Form.Label column sm={6}>
          Lock Time (frames)
        </Form.Label>
        <Col sm={3}>
          <Form.Control
            type="number"
            min="0"
            max="24247"
            value={lockTime}
            onChange={e => {
              if (e.target.value === "") {
                setLockTime("")
              } else {
                setLockTime(parseInt(e.target.value, 10))
              }
            }}
          />
        </Col>
      </Form.Group>
    </Form>
  )
}

export default TabGameSettings
