import React, { useState, useEffect } from "react"
import { Card, Nav, Form, Row, Col, Container, Button } from "react-bootstrap"
import styled from "styled-components"

const PuyoImg = styled.img`
  object-fit: none;
  width: 64px;
  height: 60px;
`

// Defaults
const colorList = ["Red", "Green", "Blue", "Yellow", "Purple", "Garbage"]
const colors = {}
colorList.forEach(
  color =>
    (colors[color] = {
      hue: 0,
      sat: 100,
      bri: 100,
      con: 100,
    })
)

const PuyoColorEdit = ({ imgsrc, colorEditData, setColorEditData }) => {
  // const [colorEditData, setColorEditData] = useState(colorEditData)
  const [currentTab, setCurrentTab] = useState("Red")

  const NavItems = () =>
    Object.keys(colorEditData).map((color, i) => (
      <Nav.Item key={color}>
        <Nav.Link eventKey={color}>
          <PuyoImg
            src={imgsrc.replace(".png", "_trim.png")}
            style={{
              objectPosition: `0 ${-72 * i}px`,
              filter: `${color === "Garbage" && (colorEditData["Garbage"].hue !== 0 || colorEditData["Garbage"].sat !== 100 || colorEditData["Garbage"].bri !== 100 || colorEditData["Garbage"].con !== 100) ? `sepia(100%)` : ``} hue-rotate(${colorEditData[color].hue}deg) saturate(${
                colorEditData[color].sat
              }%) brightness(${colorEditData[color].bri}%) contrast(${
                colorEditData[color].con
              }%)`,
            }}
          />
        </Nav.Link>
      </Nav.Item>
    ))

  // const checkIfDefaultSettings = () => {
  //   for (let color in colorEditData) {
  //     if (colorEditData[color].hue !== 0) return false
  //     if (colorEditData[color].sat !== 100) return false
  //     if (colorEditData[color].bri !== 100) return false
  //     if (colorEditData[color].con !== 100) return false
  //   }
  //   return true
  // }

  const resetPuyoToDefault = () => {
    const newPuyoSettings = { ...colorEditData }
    newPuyoSettings[currentTab].hue = 0
    newPuyoSettings[currentTab].sat = 100
    newPuyoSettings[currentTab].bri = 100
    newPuyoSettings[currentTab].con = 100
    setColorEditData(newPuyoSettings)
  }

  const resetAllToDefault = () => {
    setColorEditData(colors)
  }

  useEffect(() => {
    // console.log(
    //   `Are the colors at the default settings? ${checkIfDefaultSettings()}`
    // )
    setColorEditData(colorEditData)
  })

  return (
    <Card className="mt-2">
      <Card.Header>
        <Nav
          variant="tabs"
          defaultActiveKey="Red"
          onSelect={key => setCurrentTab(key)}
        >
          <NavItems />
        </Nav>
      </Card.Header>
      <Card.Body>
        <Card.Text>
          Editing Puyo: {currentTab}
        </Card.Text>
        <Form as={Container}>
          <Form.Group as={Row} controlId="Hue">
            <Form.Label sm={2}>Hue:</Form.Label>
            <Col sm={6}>
              <Form.Control
                type="range"
                min="0"
                max="360"
                value={colorEditData[currentTab].hue}
                onChange={e => {
                  const newPuyoSettings = { ...colorEditData }
                  newPuyoSettings[currentTab].hue = parseInt(e.target.value, 10)
                  setColorEditData(newPuyoSettings)
                }}
              />
            </Col>
            <Form.Label sm={4}>
              {colorEditData[currentTab].hue} degrees
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} controlId="Saturation">
            <Form.Label sm={2}>Saturation:</Form.Label>
            <Col sm={6}>
              <Form.Control
                type="range"
                min="0"
                max="200"
                value={colorEditData[currentTab].sat}
                onChange={e => {
                  const newPuyoSettings = { ...colorEditData }
                  newPuyoSettings[currentTab].sat = parseInt(e.target.value, 10)
                  setColorEditData(newPuyoSettings)
                }}
              />
            </Col>
            <Form.Label sm={4}>{colorEditData[currentTab].sat}%</Form.Label>
          </Form.Group>
          <Form.Group as={Row} controlId="Brightness">
            <Form.Label sm={2}>Brightness:</Form.Label>
            <Col sm={6}>
              <Form.Control
                type="range"
                min="0"
                max="200"
                value={colorEditData[currentTab].bri}
                onChange={e => {
                  const newPuyoSettings = { ...colorEditData }
                  newPuyoSettings[currentTab].bri = parseInt(e.target.value, 10)
                  setColorEditData(newPuyoSettings)
                }}
              />
            </Col>
            <Form.Label sm={4}>{colorEditData[currentTab].bri}%</Form.Label>
          </Form.Group>
          <Form.Group as={Row} controlId="Contrast">
            <Form.Label sm={2}>Contrast:</Form.Label>
            <Col sm={6}>
              <Form.Control
                type="range"
                min="0"
                max="200"
                value={colorEditData[currentTab].con}
                onChange={e => {
                  const newPuyoSettings = { ...colorEditData }
                  newPuyoSettings[currentTab].con = parseInt(e.target.value, 10)
                  setColorEditData(newPuyoSettings)
                }}
              />
            </Col>
            <Form.Label sm={4}>{colorEditData[currentTab].con}%</Form.Label>
          </Form.Group>
          <Button onClick={resetPuyoToDefault}>Reset {currentTab} Puyo</Button>{" "}
          <Button onClick={resetAllToDefault}>Reset All Puyos</Button>
        </Form>
      </Card.Body>
    </Card>
  )
}

export default PuyoColorEdit
