import React from "react"
import styled from "styled-components"

const PuyoContainer = styled.div`
  display: inline-block;
  padding: 6px;
  border-radius: 50%;
`

const PuyoImg = styled.img`
  object-fit: none;
  animation: 10s newPuyo infinite step-end;
  width: 64px;
  height: 60px;

  @keyframes newPuyo {
    0% { object-position: 0 0px; }
    20% { object-position: 0 -72px; }
    40% { object-position: 0 -144px; }
    60% { object-position: 0 -216px; }
    80% { object-position: 0 -288px; }
  }
`

const PuyoThumbnail = ({imgsrc, active, setPuyoSkinIndex, index}) => {
  // console.log(imgsrc.replace(".png", "_trim.png"))
  return (
    <PuyoContainer onClick={() => {
      // setPuyoSkin(imgsrc)
      setPuyoSkinIndex(index)
    }} style={{backgroundColor: active ? `rgba(0, 0, 0, 0.5)` : ``}}>
      <PuyoImg src={imgsrc.replace(".png", "_trim.png")} />
    </PuyoContainer>
  )
}

export default PuyoThumbnail