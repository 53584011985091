import React from "react"
import { Form } from "react-bootstrap"
import KeySetter from "./KeySetter"

const TabEditControls = ({ keyboard, updateKeyBinds }) => {
  return (
    <Form>
      <KeySetter
        buttonName="left"
        displayName="Left"
        defaultKey="ArrowLeft"
        updateKeyBinds={updateKeyBinds}
        keyboard={keyboard}
      />
      <KeySetter
        buttonName="right"
        displayName="Right"
        defaultKey="ArrowRight"
        updateKeyBinds={updateKeyBinds}
        keyboard={keyboard}
      />
      <KeySetter
        buttonName="down"
        displayName="Down"
        defaultKey="ArrowDown"
        updateKeyBinds={updateKeyBinds}
        keyboard={keyboard}
      />
      <KeySetter
        buttonName="ccw"
        displayName="CCW Rotate"
        defaultKey="KeyZ"
        updateKeyBinds={updateKeyBinds}
        keyboard={keyboard}
      />
      <KeySetter
        buttonName="cw"
        displayName="CW Rotate"
        defaultKey="KeyX"
        updateKeyBinds={updateKeyBinds}
        keyboard={keyboard}
      />
      <KeySetter
        buttonName="undo"
        displayName="Undo"
        defaultKey="Comma"
        updateKeyBinds={updateKeyBinds}
        keyboard={keyboard}
      />
      <KeySetter
        buttonName="redo"
        displayName="Redo/Hint"
        defaultKey="Period"
        updateKeyBinds={updateKeyBinds}
        keyboard={keyboard}
      />
    </Form>
  )
}

export default TabEditControls
