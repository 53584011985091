import React, { useState, useRef, useEffect, useCallback } from "react"
import { Row, Container, Col, Tab, Tabs, Card } from "react-bootstrap"
// import esportsBackground from "../../../static/sim_assets/img/espo_bg.jpg"
import TabCreateDrill from "./TabCreateDrill"
import TabExportReactComponent from "./TabExportReactComponent"
import styled from "styled-components"
import TabShareToFirebase from "./TabShareToFirebaseV2"
import TabCreateSlideshow from "./TabCreateSlideshow"
import TabGameSettings from "./TabGameSettings"
import TabEditControls from "./TabEditControls"
import TabGraphicsSettings from "./TabGraphicsSettings"
import ChainsimFloater from "../chainsim-floater"

// Styled Components
const ChainsimCard = styled(Card)`
  background-color: #E8E8E8;
  padding: 8px;
  background-size: "cover",
  background-position: "center",
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const GameContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const LoadingBox = styled.div`
  color: white;
  font-weight: bold;
  font-size: 1.3em;
  text-shadow: 2px 2px black;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  width: 90%;
  height: auto;
  margin-top: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  .load-wheel {
    width: 60px;
    height: 60px;
    animation: spin 4s linear infinite;
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

const ChainsimFull = props => {
  const [gameLoaded, setGameLoaded] = useState(false)
  const [floaterLoaded, setFloaterLoaded] = useState(false)
  const [sharedLoader, setSharedLoader] = useState(null)
  const [background, setBackground] = useState({
    backgroundImage: `url("/sim_assets/img/espo_bg.jpg")`,
    backgroundColor: `none`,
  })

  const gameNode = useRef(null)
  const gameContainer = useRef(null)
  const game = useRef()
  const gameMounted = useRef(false)
  const keyboard = useRef({
    right: "ArrowRight",
    down: "ArrowDown",
    left: "ArrowLeft",
    cw: "KeyX",
    ccw: "KeyZ",
    undo: "Comma",
    redo: "Period",
  })

  const updateKeyBinds = (button, code) => {
    keyboard.current[button] = code
  }

  const handleKeyBindsForGame = useCallback(e => {
    if (gameNode.current.contains(e.target)) {
      game.current.bindKeyboardKeys(keyboard.current)
      // console.log("Binded keys")
    } else {
      game.current.unbindKeyboardKeys()
      // console.log("Unbinded keys")
    }
  })

  const updateHistory = data => {
    const {
      title,
      author,
      category,
      description,
      outputFolder,
      date,
      tagsToggle,
    } = data
    game.current.state.history.title = title
    game.current.state.history.author = author
    game.current.state.history.category = category
    game.current.state.history.description = description
    game.current.state.history.outputFolder = outputFolder
    game.current.state.history.date = date
    game.current.state.history.tags = Object.keys(tagsToggle).filter(
      key => tagsToggle[key]
    )
    // console.log("Updated chainsim-game metadata.")
  }

  // Mount the chainsim-game
  useEffect(() => {
    console.log("This should only run once.")
    import("./ChainsimPlayable.js")
      .then(module => {
        const ChainsimPlayable = module.default
        if (!props.json) {
          game.current = new ChainsimPlayable(
            gameNode.current,
            undefined,
            setGameLoaded,
            setSharedLoader,
            props.autoPlay
          )
        } else {
          game.current = new ChainsimPlayable(
            gameNode.current,
            JSON.parse(JSON.stringify(props.json)),
            setGameLoaded,
            setSharedLoader
          )
        }
      })
      .then(() => {
        const resize = () => {
          game.current.app.view.style.height = `${gameContainer.current.getBoundingClientRect()
            .width * 1.5873015873}px`
          game.current.app.view.style.width = `${
            gameContainer.current.getBoundingClientRect().width
          }px`

          // ... But, if the game is taller than the window, resize it again.
          const gameHeight = parseFloat(
            game.current.app.view.style.height.replace(/[^\d.-]/g, "")
          )
          if (gameHeight > window.innerHeight) {
            game.current.app.view.style.height = `${window.innerHeight}px`
            game.current.app.view.style.width = `${window.innerHeight * 0.63}px`
          }
        }

        resize()
        window.onresize = resize

        gameMounted.current = true
      })

    // Add event listener for detecting click inside/outside chainsim game
    document.addEventListener("mousedown", handleKeyBindsForGame, false)

    return () => {
      game.current.clearTextureCache()
      document.removeEventListener("mousedown", handleKeyBindsForGame, false)
      // console.log("unbinded keys")
    }
  }, [])

  return (
    <Container>
      {gameLoaded && (
        <ChainsimFloater
          sharedLoader={sharedLoader}
          setFloaterLoaded={setFloaterLoaded}
        />
      )}
      <h1 className="mt-4">Playable Chain Simulator</h1>
      <Row>
        <Col lg={6}>
          <ChainsimCard style={background}>
            {!gameLoaded && (
              <LoadingBox>
                <Col xs={3} className="d-flex flex-row justify-content-center">
                  <img
                    className="load-wheel"
                    src="/sim_assets/img/save_wheel.png"
                    alt=""
                  />{" "}
                </Col>
                <Col xs={9} className="d-flex flex-row justify-content-center">
                  Loading! Please wait.
                  <br />
                  If you continue to see this message, then you might have WebGL
                  disabled. Go into your browser settings and turn on hardware
                  acceleration.
                </Col>
              </LoadingBox>
            )}
            <GameContainer ref={gameContainer}>
              <div ref={gameNode} />
            </GameContainer>
          </ChainsimCard>
        </Col>
        <Col>
          <Tabs defaultActiveKey="drill" id="simulator-settings">
            <Tab eventKey="share" title="🔗 Share">
              Create share links with the help of firebase
              <TabShareToFirebase
                game={game.current}
                floaterLoaded={floaterLoaded}
              />
            </Tab>
            <Tab eventKey="react" title="⚛️ React Component">
              Create React component code block for use in guide writing.
              <TabExportReactComponent game={game} />
            </Tab>
            <Tab eventKey="drill" title="🏃 Drill">
              Export JSON file for chain drills.
              <TabCreateDrill
                gameMounted={gameMounted}
                updateHistory={updateHistory}
              />
            </Tab>
            <Tab eventKey="slideshow" title="🎞️ Slideshow">
              <TabCreateSlideshow game={game} />
            </Tab>
            <Tab eventKey="game" title="⚙️ Game Settings">
              <TabGameSettings game={game} gameMounted={gameMounted} />
            </Tab>
            <Tab eventKey="graphics" title="🎨 Graphics Settings">
              <TabGraphicsSettings setBackground={setBackground} />
            </Tab>
            <Tab eventKey="controls" title="🕹️ Controls">
              <h3>Edit Keyboard Controls</h3>
              <TabEditControls updateKeyBinds={updateKeyBinds} />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  )
}

export default ChainsimFull
