import React, { useCallback, useEffect, useState, useRef } from "react"
import { Form, Row, Col } from "react-bootstrap"

const KeySetter = props => {
  const [keyCode, setKeyCode] = useState(props.defaultKey)
  const [detectKeyPress, setDetectKeyPress] = useState(false)
  const keyInput = useRef(null)

  // When the component re-renders, it creates a different instance of all these functions.
  // AddEventListener and RemoveEventListener won't work if you don't give them the exact same
  // instance of the functions.
  // useCallback gets around that.
  const catchKeyCode = useCallback(e => {
    e.preventDefault()
    setKeyCode(e.code)
    props.updateKeyBinds(props.buttonName, e.code)
  })

  // Ensure that event listeners get cleaned up when page changes
  useEffect(() => {
    if (detectKeyPress) {
      // console.log("Adding event listener")
      window.addEventListener("keydown", catchKeyCode, false)
    } else {
      // console.log("Removing event listener")
      window.removeEventListener("keydown", catchKeyCode, false)
    }

    return () => {
      // console.log("Removing event listener")
      window.removeEventListener("keydown", catchKeyCode, false)
    }
  })

  return (
    <Form.Group as={Row} controlId={`keySetter-${props.buttonName}`}>
      <Form.Label column sm={3}>
        {props.displayName}:
      </Form.Label>
      <Col sm={9}>
        <Form.Control
          ref={keyInput}
          type="text"
          value={keyCode}
          onChange={() => {}}
          onFocus={() => setDetectKeyPress(true)}
          onBlur={() => setDetectKeyPress(false)}
        />
      </Col>
    </Form.Group>
  )
}

export default KeySetter
