import React, { useState, useEffect, useRef } from "react"
import { Container, Button, Form, Row, Col, InputGroup } from "react-bootstrap"
import { coercePuyoMatrix } from "./chainsim_functions/MatrixFunctions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload, faCopy } from "@fortawesome/free-solid-svg-icons"
import gifshot from "gifshot"

const THUMBNAIL_HEIGHT = 890
const THUMBNAIL_WIDTH = 436
const SLIDESHOW_WIDTH = 632

// const SLIDESHOW_HEIGHT = 445

const dataURIToBlob = dataURI => {
  //https://stackoverflow.com/questions/38781968/problems-downloading-big-filemax-15-mb-on-google-chrome
  // chrome has a 2mb limit on anchor tags.
  let binStr = atob(dataURI.split(',')[1]),
    len = binStr.length,
    arr = new Uint8Array(len),
    mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }

  return new Blob([arr], {
    type: mimeString
  });
}

const initCanvasRefs = () => {
  const cvs = useRef()
  const ctx = useRef()

  useEffect(() => {
    cvs.current = document.createElement("canvas")
    ctx.current = cvs.current.getContext("2d")
  }, [])

  return [cvs.current, ctx.current]
}

const TabShareToFirebase = ({ game, floaterLoaded }) => {
  const [sayToWait, setSayToWait] = useState(false)

  const imageID = useRef("")
  const [currentUniqueID, setCurrentUniqueID] = useState("")
  const [thumbnailDataURL, setThumbnailDataURL] = useState("")

  const [thumbnailURL, setThumbnailURL] = useState("")
  const [pageURL, setPageURL] = useState("")

  const [gifURL, setGifURL] = useState("")
  const [gifDataURL, setGifDataURL] = useState("")

  // Canvas vars
  const [cvs, ctx] = initCanvasRefs()

  // Firebase
  const firebaseModule = useRef(null)
  const firebase = firebaseModule.current

  // Aliases
  let chainsimFloater
  if (typeof window !== `undefined`) {
    if (window.chainsim !== undefined) {
      if (window.chainsim.game !== undefined) {
        chainsimFloater = window.chainsim.game
      }
    }
  }

  // Thumbnail image array
  const thumbnailArray = useRef([])
  const thumbnails = thumbnailArray.current

  // Generation Status
  const [status, setStatus] = useState("")

  useEffect(() => {
    // Initialize Firebase
    Promise.all([
      import("firebase/app"),
      import("firebase/storage"),
    ])
    .then(x => x[0].default)
    .then(fb => {
      const config = {
        apiKey: "AIzaSyBX-oJ4Ucnca7kdyd8Fg9l9LqRfnZNJ5Fg",
        authDomain: "puyo-gg.firebaseapp.com",
        databaseURL: "https://puyo-gg.firebaseio.com",
        projectId: "puyo-gg",
        storageBucket: "puyo-gg.appspot.com",
        messagingSenderId: "833930022623",
        appId: "1:833930022623:web:981493bac41fb1e1"
      }

      fb.initializeApp(config)

      firebaseModule.current = fb
    })

    return () => {
      firebaseModule.current = null
    }
  }, [])

  const generateMainThumbnail = () => {
    if (!chainsimFloater) return
    if (!floaterLoaded) return

    console.log("Generating main thumbnail")

    const setChainsimStates = () => {
      chainsimFloater.state.game.paused = true
      chainsimFloater.state.history = game.state.history
      chainsimFloater.state.next.currentQueuePosition =
        game.state.next.currentQueuePosition
      const currentSlide = game.state.next.currentQueuePosition / 2
      chainsimFloater.chainsim.inputMatrix =
        game.state.history.fields[currentSlide].puyo
      chainsimFloater.chainsim.updateFieldMatrix(
        game.state.history.fields[currentSlide].puyo
      )
      chainsimFloater.state.field.shadow = coercePuyoMatrix(
        game.state.history.fields[currentSlide].shadow,
        game.state.settings
      )
      chainsimFloater.state.field.arrow = JSON.parse(
        JSON.stringify(game.state.history.fields[currentSlide].arrow)
      )
      chainsimFloater.state.field.cursor = JSON.parse(
        JSON.stringify(game.state.history.fields[currentSlide].cursor)
      )
      chainsimFloater.state.field.number = JSON.parse(
        JSON.stringify(game.state.history.fields[currentSlide].number)
      )
      chainsimFloater.objects.gameControls.edit.visible = false
      chainsimFloater.resetFieldAndState()
      chainsimFloater.generateColorQueue()
      chainsimFloater.refreshActivePairSprites()
      chainsimFloater.refreshNextPuyoSprites()

      // Set canvas size to small thumbnail
      chainsimFloater.app.view.width = THUMBNAIL_WIDTH
      chainsimFloater.app.view.height = THUMBNAIL_HEIGHT
      cvs.width = THUMBNAIL_WIDTH
      cvs.height = THUMBNAIL_HEIGHT - 40
      ctx.imageSmoothingEnabled = false
      setSayToWait(true)
      setStatus("Generating main thumbnail...")
      requestAnimationFrame(generateImageFromState)
    }

    const generateImageFromState = () => {
      ctx.drawImage(
        chainsimFloater.app.view,
        0,
        -40,
        THUMBNAIL_WIDTH,
        THUMBNAIL_HEIGHT
      )
      const dataURL = cvs.toDataURL("image/png")
      const uniqueID =
        new Date().valueOf().toString(36) +
        Math.round(Math.random() * 240).toString(36)

      fetch("/.netlify/functions/chain", {
        method: "POST",
        body: JSON.stringify({
          uniqueID: uniqueID,
          history: game.state.history,
          slide: game.state.next.currentQueuePosition / 2,
          slim: dataURL,
        }),
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      })
        .then(response => {
          return response.json()
        })
        .then(json => {
          setThumbnailURL(json.publicURL)
          setPageURL(`https://www.puyo.gg/simulator/chain/${uniqueID}`)
          imageID.current = uniqueID
          setCurrentUniqueID(uniqueID)
          setThumbnailDataURL(dataURL)
          // setSayToWait(false)
          setStatus("Generating slideshow images...")
          requestAnimationFrame(generateSlideshow)
        })
    }

    // Start steps to generate thumbnail image
    requestAnimationFrame(setChainsimStates)
  }

  const generateSlideshow = () => {
    if (!chainsimFloater) return
    if (!floaterLoaded) return

    console.log("Generating slideshow thumbnails")
    // Set queue position back to the start
    chainsimFloater.state.next.currentQueuePosition = 0

    const setChainsimStates = () => {
      const currentSlide = chainsimFloater.state.next.currentQueuePosition / 2
      chainsimFloater.chainsim.inputMatrix =
        game.state.history.fields[currentSlide].puyo
      chainsimFloater.chainsim.updateFieldMatrix(
        game.state.history.fields[currentSlide].puyo
      )
      chainsimFloater.state.field.shadow = coercePuyoMatrix(
        game.state.history.fields[currentSlide].shadow,
        game.state.settings
      )
      chainsimFloater.state.field.arrow = JSON.parse(
        JSON.stringify(game.state.history.fields[currentSlide].arrow)
      )
      chainsimFloater.state.field.cursor = JSON.parse(
        JSON.stringify(game.state.history.fields[currentSlide].cursor)
      )
      chainsimFloater.state.field.number = JSON.parse(
        JSON.stringify(game.state.history.fields[currentSlide].number)
      )
      chainsimFloater.objects.gameControls.edit.visible = false
      chainsimFloater.resetFieldAndState()
      chainsimFloater.generateColorQueue()
      chainsimFloater.refreshActivePairSprites()
      chainsimFloater.refreshNextPuyoSprites()

      // Set canvas size to slideshow size
      chainsimFloater.app.view.width = SLIDESHOW_WIDTH
      chainsimFloater.app.view.height = THUMBNAIL_HEIGHT
      cvs.width = SLIDESHOW_WIDTH
      cvs.height = THUMBNAIL_HEIGHT - 40
      ctx.imageSmoothingEnabled = false

      requestAnimationFrame(generateSlideFromState)
    }

    const generateSlideFromState = () => {
      ctx.drawImage(
        chainsimFloater.app.view,
        0,
        -40,
        SLIDESHOW_WIDTH,
        THUMBNAIL_HEIGHT
      )
      const dataURL = cvs.toDataURL("image/png")

      thumbnails.push(dataURL)
      console.log(thumbnails)

      if (
        chainsimFloater.state.next.currentQueuePosition / 2 <
        chainsimFloater.state.history.fields.length - 1
      ) {
        chainsimFloater.state.next.currentQueuePosition += 2
        requestAnimationFrame(setChainsimStates)
      } else {
        setStatus("Generating GIF... 0%")
        generateGIF()
      }
    }

    const generateGIF = () => {
      console.log("Trying to generate gif")
      gifshot.createGIF({
        "gifWidth": SLIDESHOW_WIDTH / 2,
        "gifHeight": THUMBNAIL_HEIGHT / 2,
        "images": thumbnails,
        "frameDuration": 10,
        "progressCallback": captureProgress => setStatus(`Generating GIF... ${Math.round(captureProgress * 100)}%`)
      }, obj => {
        if (!obj.error) {
          setGifDataURL(obj.image)
          console.log(obj.image.slice(0, 50))
          console.log("Successfully made GIF?")
          setStatus("Uploading GIF to online storage...")
          requestAnimationFrame(() => uploadGIFToFirebase(obj.image))
        } else {
          console.log("Error generating GIF")
          setStatus("Error.")
        }
      })
    }

    const uploadGIFToFirebase = gif => {
      const head = "data:image/png;base64,"
      const filesize = Math.round((gif.length - head.length) * 3 / 4)
      console.log(`File size in megabytes?: ${Math.round(filesize / 10000) / 100}`)

      if (filesize > 20 * 1000000) {
        alert("Sorry, you can't upload gifs larger than 20 MB.")
        return
      }

      const storageRef = firebase.storage().ref()
      const imageRef = storageRef.child(`gifs/${imageID.current}.gif`)
      imageRef.putString(gif, "data_url").then(snapshot => {
        setGifURL(`http://storage.googleapis.com/puyo-gg.appspot.com/gifs%2F${imageID.current}.gif`)
        setStatus("Uploading GIF to online storage...")
        setSayToWait(false)
      })
      .catch(err => {
        console.log(err)
        setStatus("There was a problem uploading the GIF to online Storage.")
      })
    }

    requestAnimationFrame(setChainsimStates)
  }

  return (
    <Container fluid className="mt-2">
      <Row>
        <Col xs="auto">
          <Button onClick={generateMainThumbnail}>Generate</Button>
        </Col>
        <Col className="d-flex flex-row align-items-center">
          {sayToWait && (
            <span>{status}</span>
          )}
        </Col>
      </Row>
      <Form className="py-4">
        <Form.Group as={Row} controlId="pageURL" className="align-items-center">
          <Col xs={4} className="d-flex flex-row align-items-center">
            <Form.Label className="m-0">Page</Form.Label>
          </Col>
          <Col>
            <InputGroup>
              <Form.Control type="text" value={pageURL} readOnly />
              <InputGroup.Append>
                <Button
                  variant="outline-secondary"
                  onClick={() => navigator.clipboard.writeText(pageURL)}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          controlId="thumbnailURL"
          className="align-items-center"
        >
          <Col xs={4} className="d-flex flex-row align-items-center">
            <Form.Label className="m-0">Thumbnail</Form.Label>
          </Col>
          <Col>
            <InputGroup>
              <Form.Control type="text" value={thumbnailURL} readOnly />
              <InputGroup.Append>
                <Button variant="outline-secondary"
                  onClick={() => {
                    const blob = dataURIToBlob(thumbnailDataURL)
                    const url = URL.createObjectURL(blob)
                    const a = document.createElement("a")
                    a.download = `${currentUniqueID}.png`
                    a.href = url
                    document.body.appendChild(a)
                    a.click()
                    requestAnimationFrame(() => {
                      document.body.removeChild(a)
                      URL.revokeObjectURL(url)
                    })
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
              </InputGroup.Append>
              <InputGroup.Append>
                <Button
                  variant="outline-secondary"
                  onClick={() => navigator.clipboard.writeText(thumbnailURL)}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          controlId="gifURL"
          className="align-items-center"
        >
          <Col xs={4} className="d-flex flex-row align-items-center">
            <Form.Label className="m-0">GIF</Form.Label>
          </Col>
          <Col>
            <InputGroup>
              <Form.Control type="text" value={gifURL} readOnly />
              <InputGroup.Append>
                <Button variant="outline-secondary"
                  onClick={() => {
                    const blob = dataURIToBlob(gifDataURL)
                    const url = URL.createObjectURL(blob)
                    const a = document.createElement("a")
                    a.download = `${currentUniqueID}.gif`
                    a.href = url
                    document.body.appendChild(a)
                    a.click()
                    requestAnimationFrame(() => {
                      document.body.removeChild(a)
                      URL.revokeObjectURL(url)
                    })
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
              </InputGroup.Append>
              <InputGroup.Append>
                <Button
                  variant="outline-secondary"
                  onClick={() => navigator.clipboard.writeText(gifURL)}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Form.Group>
      </Form>
    </Container>
  )
}

export default TabShareToFirebase
