import React from "react"
import styled from "styled-components"

const CharContainer = styled.div`
  display: inline-block;
  position: relative;
  padding: 6px;
  width: 130px;
  height: 100px;
`

const CharImage = styled.img`
  position: relative;
  width: 100%;
  height: auto;
`

const CharThumbnail = ({imgsrc, bgToSet, active, index, category, charName, setCharName, setCharBG, setCharBGIndex}) => {
  return (
    <CharContainer
      onClick={() => {
        setCharBG(bgToSet)
        setCharBGIndex({ cat: category, index: index })
        setCharName(charName)
        // console.log(index)
      }} 
    >
      <CharImage
        src={imgsrc}
        style={{
          filter: active ? `drop-shadow(6px 6px 6px #444444)` : ``,
          WebkitFilter: active ? `drop-shadow(6px 6px 6px #444444)` : ``,
          MozFilter: active ? `drop-shadow(6px 6px 6px #444444)` : ``,
          top: active ? `-6px` : ``,
          left: active ? `-4px` : ``
        }}
      />
    </CharContainer>
  )
}

export default CharThumbnail