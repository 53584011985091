import React, { useEffect, useState } from "react"
// import { Row, Col, Container } from "react-bootstrap"
import firebase from "firebase/app"
import "firebase/firestore"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ChainsimFull from "../components/chainsim-full"
import { rebuild2DArrayFromString } from "../components/chainsim-full/chainsim_functions/MatrixFunctions"
// import ChainsimFloater from "../components/chainsim-floater"

const SimulatorPage = props => {
  const [chainID, setChainID] = useState("")
  const [urlParams, setUrlParams] = useState([])
  const [history, setHistory] = useState(null)
  const [paramsLoaded, setParamsLoaded] = useState(false)
  // const [hasDataFromFirebase, setHasDataFromFirebase] = useState(false)

  useEffect(() => {
    const urlParameters = props.location.pathname
      .replace("/simulator/", "")
      .split("/")
    let chainIDFromURL
    if (urlParameters[0] === "chain" || urlParameters[0] === "steps") {
      chainIDFromURL = urlParameters[1]
    }
    console.log(urlParameters)
    console.log(chainIDFromURL)
    setChainID(chainIDFromURL)
    setUrlParams(urlParameters)

    // If the length of the chain ID is greater than 0, try checking for it in Firebase
    if (chainIDFromURL && chainIDFromURL.length > 0) {
      // Initialize firebase to search for the chain ID.
      // This API Key is meant to be public. Security rules block everything except for single get requests.
      console.log(firebase.apps)
      firebase.initializeApp({
        apiKey: "AIzaSyBX-oJ4Ucnca7kdyd8Fg9l9LqRfnZNJ5Fg",
        authDomain: "puyo-gg.firebaseapp.com",
        databaseURL: "https://puyo-gg.firebaseio.com",
        projectId: "puyo-gg",
        storageBucket: "puyo-gg.appspot.com",
        messagingSenderId: "833930022623",
        appId: "1:833930022623:web:981493bac41fb1e1",
      })

      const db = firebase.firestore()
      const chainIdRef = db.collection("chains").doc(chainIDFromURL)

      chainIdRef
        .get()
        .then(doc => {
          console.log(doc)
          const data = doc.data()
          console.log(data)
          const history = data.history
          console.log(history)

          // Put field strings back to 2D arrays.
          history.fields.forEach(fieldset => {
            fieldset.puyo = rebuild2DArrayFromString(
              fieldset.puyo,
              history.settings
            )
            fieldset.shadow = rebuild2DArrayFromString(
              fieldset.shadow,
              history.settings
            )
            fieldset.arrow = rebuild2DArrayFromString(
              fieldset.arrow,
              history.settings
            )
            fieldset.cursor = rebuild2DArrayFromString(
              fieldset.cursor,
              history.settings
            )
            fieldset.number = rebuild2DArrayFromString(
              fieldset.number,
              history.settings
            )
          })

          setHistory(history)
        })
        .then(() => {
          setParamsLoaded(true)
        })
        .catch(err => {
          console.log(err)
          setParamsLoaded(true)
        })
    } else {
      setParamsLoaded(true)
    }
  }, [])

  return (
    <Layout location={props.location}>
      {paramsLoaded && (
        <>
          <SEO
            title="Puyo Puyo Chain Simulator"
            keywords={[`Puyo Puyo`, `Chain`, `Simulator`, `Playable`]}
            chainID={chainID}
            urlParams={urlParams}
            simulator
          />
          <ChainsimFull json={history} autoPlay={false} />
        </>
      )}
    </Layout>
  )
}

export default SimulatorPage
