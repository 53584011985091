import React, { useState } from "react"
import { Form, Row, Col, Button } from "react-bootstrap"
import {
  copyPrimitiveArray,
  convertPuyoToStringArray,
} from "./chainsim_functions/MatrixFunctions"

const TabExportReactComponent = props => {
  const [caption, setCaption] = useState("")
  const [componentData, setComponentData] = useState("")

  const updateComponentData = () => {
    // Update hidden mini chainsim
    // console.log(props)
    const field = {
      puyo: copyPrimitiveArray(props.game.current.chainsim.matrixText),
      shadow: convertPuyoToStringArray(props.game.current.state.field.shadow),
      arrow: copyPrimitiveArray(props.game.current.state.field.arrow),
      cursor: copyPrimitiveArray(props.game.current.state.field.cursor),
      number: copyPrimitiveArray(props.game.current.state.field.number),
    }

    const componentPaste =
      caption.length === 0
        ? `<ChainsimContainer\njson={${JSON.stringify(field)}} />`
            .replace(`"puyo"`, `puyo`)
            .replace(`"shadow"`, `shadow`)
            .replace(`"arrow"`, `arrow`)
            .replace(`"cursor"`, `cursor`)
        : `<ChainsimContainer\ncaption="${caption}"\njson={${JSON.stringify(
            field
          )}} />`
            .replace(`"puyo"`, `puyo`)
            .replace(`"shadow"`, `shadow`)
            .replace(`"arrow"`, `arrow`)
            .replace(`"cursor"`, `cursor`)

    setComponentData(componentPaste)
  }

  return (
    <Form>
      <Form.Group as={Row} controlId="caption">
        <Col>
          <Form.Control
            type="text"
            placeholder="Caption"
            value={caption}
            onChange={e => setCaption(e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group controlId="fieldjson">
        <Form.Control as="textarea" rows="16" value={componentData} readOnly />
      </Form.Group>
      <Button onClick={updateComponentData}>Generate React Component</Button>
    </Form>
  )
}

export default TabExportReactComponent
