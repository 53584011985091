const defaultColors = {
  Red: {
    hue: 0,
    sat: 100,
    bri: 100,
    con: 100,
  },
  Green: {
    hue: 0,
    sat: 100,
    bri: 100,
    con: 100,
  },
  Blue: {
    hue: 0,
    sat: 100,
    bri: 100,
    con: 100,
  },
  Yellow: {
    hue: 0,
    sat: 100,
    bri: 100,
    con: 100,
  },
  Purple: {
    hue: 0,
    sat: 100,
    bri: 100,
    con: 100,
  },
  Garbage: {
    hue: 0,
    sat: 100,
    bri: 100,
    con: 100,
  },
}

const skins = {
  Aqua: {
    url: "/sim_assets/img/puyo_aqua.png",
    customUrl: "",
    useCustomUrl: false,
    settings: {
      Red: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Green: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Blue: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Yellow: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Purple: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Garbage: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
    }
  },
  Fever: {
    url: "/sim_assets/img/puyo_fever.png",
    customUrl: "",
    useCustomUrl: false,
    settings: {
      Red: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Green: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Blue: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Yellow: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Purple: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Garbage: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
    }
  },
  Metallic: {
    url: "/sim_assets/img/puyo_shiki.png",
    customUrl: "",
    useCustomUrl: false,
    settings: {
      Red: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Green: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Blue: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Yellow: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Purple: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Garbage: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
    }
  },
  Classic: {
    url: "/sim_assets/img/puyo_classic.png",
    customUrl: "",
    useCustomUrl: false,
    settings: {
      Red: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Green: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Blue: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Yellow: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Purple: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Garbage: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
    }
  },
  Flat: {
    url: "/sim_assets/img/puyo_beta.png",
    customUrl: "",
    useCustomUrl: false,
    settings: {
      Red: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Green: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Blue: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Yellow: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Purple: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Garbage: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
    }
  },
  Block: {
    url: "/sim_assets/img/puyo_block.png",
    customUrl: "",
    useCustomUrl: false,
    settings: {
      Red: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Green: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Blue: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Yellow: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Purple: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Garbage: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
    }
  },
  Capsule: {
    url: "/sim_assets/img/puyo_capsule.png",
    customUrl: "",
    useCustomUrl: false,
    settings: {
      Red: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Green: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Blue: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Yellow: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Purple: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Garbage: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
    }
  },
  Retro: {
    url: "/sim_assets/img/puyo_degi.png",
    customUrl: "",
    useCustomUrl: false,
    settings: {
      Red: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Green: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Blue: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Yellow: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Purple: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Garbage: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
    }
  },
  "Game Gear": {
    url: "/sim_assets/img/puyo_gamegear.png",
    customUrl: "",
    useCustomUrl: false,
    settings: {
      Red: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Green: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Blue: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Yellow: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Purple: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Garbage: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
    }
  },
  Humanoids: {
    url: "/sim_assets/img/puyo_human.png",
    customUrl: "",
    useCustomUrl: false,
    settings: {
      Red: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Green: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Blue: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Yellow: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Purple: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Garbage: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
    }
  },
  Alphabet: {
    url: "/sim_assets/img/puyo_moji.png",
    customUrl: "",
    useCustomUrl: false,
    settings: {
      Red: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Green: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Blue: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Yellow: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Purple: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Garbage: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
    }
  },
  Morolian: {
    url: "/sim_assets/img/puyo_moro.png",
    customUrl: "",
    useCustomUrl: false,
    settings: {
      Red: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Green: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Blue: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Yellow: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Purple: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Garbage: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
    }
  },
  MSX: {
    url: "/sim_assets/img/puyo_msx.png",
    customUrl: "",
    useCustomUrl: false,
    settings: {
      Red: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Green: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Blue: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Yellow: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Purple: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Garbage: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
    }
  },
  Sonic: {
    url: "/sim_assets/img/puyo_sonic.png",
    customUrl: "",
    useCustomUrl: false,
    settings: {
      Red: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Green: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Blue: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Yellow: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Purple: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
      Garbage: {
        hue: 0,
        sat: 100,
        bri: 100,
        con: 100,
      },
    }
  },
}

const characters = {
  Champions: {
    Alex: {
      icon: "/sim_assets/img/character/champions/charselect_champions_alex.png",
      field: "/sim_assets/img/character/champions/field_alx.png",
    },
    Ally: {
      icon: "/sim_assets/img/character/champions/charselect_champions_ally.png",
      field: "/sim_assets/img/character/champions/field_aly.png",
    },
    Amitie: {
      icon:
        "/sim_assets/img/character/champions/charselect_champions_amitie.png",
      field: "/sim_assets/img/character/champions/field_ami.png",
    },
    Arle: {
      icon: "/sim_assets/img/character/champions/charselect_champions_arle.png",
      field: "/sim_assets/img/character/champions/field_arl.png",
    },
    Carbuncle: {
      icon:
        "/sim_assets/img/character/champions/charselect_champions_carbuncle.png",
      field: "/sim_assets/img/character/champions/field_car.png",
    },
    Ciel: {
      icon: "/sim_assets/img/character/champions/charselect_champions_ciel.png",
      field: "/sim_assets/img/character/champions/field_cie.png",
    },
    Draco: {
      icon:
        "/sim_assets/img/character/champions/charselect_champions_draco.png",
      field: "/sim_assets/img/character/champions/field_dra.png",
    },
    Hed: {
      icon: "/sim_assets/img/character/champions/charselect_champions_hed.png",
      field: "/sim_assets/img/character/champions/field_hed.png",
    },
    Harpy: {
      icon:
        "/sim_assets/img/character/champions/charselect_champions_harpy.png",
      field: "/sim_assets/img/character/champions/field_hrp.png",
    },
    Hartmann: {
      icon:
        "/sim_assets/img/character/champions/charselect_champions_hartmann.png",
      field: "/sim_assets/img/character/champions/field_htm.png",
    },
    Ragnus: {
      icon:
        "/sim_assets/img/character/champions/charselect_champions_ragnus.png",
      field: "/sim_assets/img/character/champions/field_lgn.png",
    },
    Maguro: {
      icon:
        "/sim_assets/img/character/champions/charselect_champions_maguro.png",
      field: "/sim_assets/img/character/champions/field_mag.png",
    },
    Penglai: {
      icon:
        "/sim_assets/img/character/champions/charselect_champions_penglai.png",
      field: "/sim_assets/img/character/champions/field_hra.png",
    },
    Raffina: {
      icon:
        "/sim_assets/img/character/champions/charselect_champions_raffina.png",
      field: "/sim_assets/img/character/champions/field_raf.png",
    },
    Risukuma: {
      icon:
        "/sim_assets/img/character/champions/charselect_champions_risukuma.png",
      field: "/sim_assets/img/character/champions/field_ris.png",
    },
    Ringo: {
      icon:
        "/sim_assets/img/character/champions/charselect_champions_ringo.png",
      field: "/sim_assets/img/character/champions/field_rng.png",
    },
    Rulue: {
      icon:
        "/sim_assets/img/character/champions/charselect_champions_rulue.png",
      field: "/sim_assets/img/character/champions/field_rul.png",
    },
    "Dark Prince": {
      icon:
        "/sim_assets/img/character/champions/charselect_champions_satan.png",
      field: "/sim_assets/img/character/champions/field_sat.png",
    },
    Schezo: {
      icon:
        "/sim_assets/img/character/champions/charselect_champions_schezo.png",
      field: "/sim_assets/img/character/champions/field_shz.png",
    },
    Sig: {
      icon: "/sim_assets/img/character/champions/charselect_champions_sig.png",
      field: "/sim_assets/img/character/champions/field_sig.png",
    },
    Serilly: {
      icon:
        "/sim_assets/img/character/champions/charselect_champions_serilly.png",
      field: "/sim_assets/img/character/champions/field_srr.png",
    },
    Sultana: {
      icon:
        "/sim_assets/img/character/champions/charselect_champions_sultana.png",
      field: "/sim_assets/img/character/champions/field_srt.png",
    },
    Suketoudara: {
      icon:
        "/sim_assets/img/character/champions/charselect_champions_suketoudara.png",
      field: "/sim_assets/img/character/champions/field_suk.png",
    },
    Witch: {
      icon:
        "/sim_assets/img/character/champions/charselect_champions_witch.png",
      field: "/sim_assets/img/character/champions/field_wch.png",
    },
  },
}

const Default = { skins, characters, defaultColors }
export default Default
