import React, { useState } from "react"
import { Form, Button } from "react-bootstrap"
import { convertPuyoToStringArray } from "./chainsim_functions/MatrixFunctions"

const TabCreateSlideshow = ({ game }) => {
  const [slideComment, setSlideComment] = useState("")
  const [slideshowJSON, setSlideshowJSON] = useState("")

  const gotoPrevSlide = () => {
    const activePair = game.current.objects.activePair.puyo
    const coordArray = game.current.state.field.coord
    const activeState = game.current.state.activePair
    const pairContainer = game.current.containers.activePair

    activeState.puyoCoord = {
      abs: [
        {
          x: coordArray[2][0].x,
          y: coordArray[2][0].y - 60,
        },
        {
          x: coordArray[2][0].x,
          y: coordArray[2][0].y - 60 * 2,
        },
      ],
      index: [{ x: 2, y: -1 }, { x: 2, y: -2 }],
    }

    pairContainer.addChild(activePair[0])
    pairContainer.addChild(activePair[1])
    activePair[0].position.set(0, 0)
    activePair[1].position.set(0, -1 * 60)
    activePair[0].scale.set(1, 1)
    activePair[1].scale.set(1, 1)
    activeState.orientation = 0
    pairContainer.visible = true

    if (game.current.state.next.currentQueuePosition / 2 > 0) {
      game.current.refreshFieldFromHistory(
        (game.current.state.next.currentQueuePosition - 2) / 2
      )
      game.current.state.next.currentQueuePosition -= 2
      game.current.refreshActivePairSprites()
      game.current.refreshNextPuyoSprites()
    }

    setSlideshowJSON(JSON.stringify(game.current.state.history))
  }

  const gotoNextSlide = () => {
    const activePair = game.current.objects.activePair.puyo
    const coordArray = game.current.state.field.coord
    const activeState = game.current.state.activePair
    const pairContainer = game.current.containers.activePair

    activeState.puyoCoord = {
      abs: [
        {
          x: coordArray[2][0].x,
          y: coordArray[2][0].y - 60,
        },
        {
          x: coordArray[2][0].x,
          y: coordArray[2][0].y - 60 * 2,
        },
      ],
      index: [{ x: 2, y: -1 }, { x: 2, y: -2 }],
    }

    pairContainer.addChild(activePair[0])
    pairContainer.addChild(activePair[1])
    activePair[0].position.set(0, 0)
    activePair[1].position.set(0, -1 * 60)
    activePair[0].scale.set(1, 1)
    activePair[1].scale.set(1, 1)
    activeState.orientation = 0
    pairContainer.visible = true

    if (
      game.current.state.next.currentQueuePosition / 2 ===
      game.current.state.history.fields.length - 1
    ) {
      game.current.state.history.fields.push({
        comment: "",
        puyo: JSON.parse(JSON.stringify(game.current.chainsim.matrixText)),
        shadow: convertPuyoToStringArray(game.current.state.field.shadow),
        arrow: JSON.parse(JSON.stringify(game.current.state.field.arrow)),
        cursor: JSON.parse(JSON.stringify(game.current.state.field.cursor)),
        number: JSON.parse(JSON.stringify(game.current.state.field.number)),
        placement: { coord: [], colors: [] },
        queuePosition: game.current.state.next.currentQueuePosition + 2,
      })
    }
    game.current.refreshFieldFromHistory(
      (game.current.state.next.currentQueuePosition + 2) / 2
    )
    game.current.state.next.currentQueuePosition += 2
    game.current.refreshActivePairSprites()
    game.current.refreshNextPuyoSprites()

    // console.log(game.current.state.next.currentQueuePosition)

    setSlideshowJSON(JSON.stringify(game.current.state.history))
  }

  return (
    <Form>
      <Form.Group controlId="slideshowjson">
        <Form.Control
          type="text"
          placeholder="Slide comment"
          value={slideComment}
          onChange={e => setSlideComment(e.target.value)}
        />
        <Form.Control as="textarea" rows="16" value={slideshowJSON} readOnly />
      </Form.Group>
      <Button onClick={gotoPrevSlide} className="mx-2">
        Prev
      </Button>
      <Button onClick={gotoNextSlide} className="mx-2">
        Next
      </Button>
      <Button
        onClick={() =>
          setSlideshowJSON(JSON.stringify(game.current.state.history))
        }
        className="mx-2"
      >
        Update
      </Button>
    </Form>
  )
}

export default TabCreateSlideshow
