import React, { useState, useEffect, useRef } from "react"
import {
  Form,
  Card,
  Nav,
  Container,
  Button,
  Badge,
  Col,
  Row,
} from "react-bootstrap"
import PuyoThumbnail from "./PuyoThumbnail"
import CharThumbnail from "./CharThumbnail"
import Default from "./OptionData"
import PuyoColorEdit from "./PuyoColorEdit"

let localForage

// let userSkins = {
//   "Custom Aqua": "/sim_assets/img/puyo_aqua.png"
// }
// let userSkins = {}

// let userCharacters = {
//   "Custom Arle": "/sim_assets/img/character/champions/field_arl.png"
// }
let userCharacters = {}

// Default Color Edit Settings
const colorList = ["Red", "Green", "Blue", "Yellow", "Purple", "Garbage"]
const colors = {}
colorList.forEach(
  color =>
    (colors[color] = {
      hue: 0,
      sat: 100,
      bri: 100,
      con: 100,
    })
)

const PuyoSkinPicker = ({
  puyoSkinList,
  puyoSkinIndex,
  // setPuyoSkin,
  setPuyoSkinIndex,
}) => {
  return Object.keys(puyoSkinList).map((name, i) => (
    <PuyoThumbnail
      imgsrc={puyoSkinList[name].url}
      active={puyoSkinIndex === i}
      key={name}
      index={i}
      // setPuyoSkin={setPuyoSkin}
      setPuyoSkinIndex={setPuyoSkinIndex}
    />
  ))
}

const BackgroundPicker = ({ setBackground }) => {
  const [useCustomBG, setUseCustomBG] = useState(false)
  const [rgbData, setRgbData] = useState({
    red: 0,
    green: 0,
    blue: 0,
    alpha: 1,
  })
  const esports = "/sim_assets/img/espo_bg.jpg"

  useEffect(() => {
    if (useCustomBG) {
      setBackground({
        backgroundImage: `none`,
        backgroundColor: `rgba(${rgbData.red}, ${rgbData.green}, ${
          rgbData.blue
        }, ${rgbData.alpha})`,
      })
    } else {
      setBackground({
        backgroundImage: `url(${esports})`,
        backgroundColor: `none`,
      })
    }
  }, [useCustomBG, rgbData])

  return (
    <div>
      <Form.Group as={Row} controlId="useCustomBackground">
        <Form.Check
          type="checkbox"
          label="Use custom background?"
          checked={useCustomBG}
          onChange={() => setUseCustomBG(!useCustomBG)}
        />
      </Form.Group>
      <Form.Group as={Row} controlId="setRed">
        <Form.Label column sm={3}>
          Red:
        </Form.Label>
        <Col sm={3}>
          <Form.Control
            type="number"
            min="0"
            max="255"
            value={rgbData.red}
            onChange={e => {
              if (e.target.value === "") {
                setRgbData({ ...rgbData, red: "" })
              } else {
                setRgbData({ ...rgbData, red: parseInt(e.target.value, 10) })
              }
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="setGreen">
        <Form.Label column sm={3}>
          Green:
        </Form.Label>
        <Col sm={3}>
          <Form.Control
            type="number"
            min="0"
            max="255"
            value={rgbData.green}
            onChange={e => {
              if (e.target.value === "") {
                setRgbData({ ...rgbData, green: "" })
              } else {
                setRgbData({ ...rgbData, green: parseInt(e.target.value, 10) })
              }
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="setBlue">
        <Form.Label column sm={3}>
          Blue:
        </Form.Label>
        <Col sm={3}>
          <Form.Control
            type="number"
            min="0"
            max="255"
            value={rgbData.blue}
            onChange={e => {
              if (e.target.value === "") {
                setRgbData({ ...rgbData, blue: parseInt(e.target.value, 10) })
              } else {
                setRgbData({ ...rgbData, blue: parseInt(e.target.value, 10) })
              }
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="setAlpha">
        <Form.Label column sm={3}>
          Alpha:
        </Form.Label>
        <Col sm={3}>
          <Form.Control
            type="number"
            min="0"
            max="1"
            step="0.01"
            value={rgbData.alpha}
            onChange={e => {
              if (e.target.value === "") {
                setRgbData({ ...rgbData, alpha: parseFloat(e.target.value) })
              } else {
                setRgbData({ ...rgbData, alpha: parseFloat(e.target.value) })
              }
            }}
          />
        </Col>
      </Form.Group>
    </div>
  )
}

const CharPicker = ({
  charBGList,
  charBGIndex,
  setCharName,
  setCharBG,
  setCharBGIndex,
}) => {
  return Object.keys(charBGList[charBGIndex.cat]).map((name, i) => (
    <CharThumbnail
      imgsrc={charBGList[charBGIndex.cat][name].icon}
      bgToSet={charBGList[charBGIndex.cat][name].field}
      active={charBGIndex.index === i}
      key={`${charBGIndex.cat}-${name}`}
      index={i}
      category={charBGIndex.cat}
      charName={name}
      setCharName={setCharName}
      setCharBG={setCharBG}
      setCharBGIndex={setCharBGIndex}
    />
  ))
}

const TabGraphicsSettings = ({ setBackground }) => {
  // const [puyoSkin, setPuyoSkin] = useState(Default.skins.Aqua.url)
  const [puyoSkinList, setPuyoSkinList] = useState(Default.skins)
  const [puyoSkinIndex, setPuyoSkinIndex] = useState(0)

  const [charName, setCharName] = useState("Arle")
  const [charBG, setCharBG] = useState(Default.characters.Champions.Arle.field)
  const [charBGList, setCharBGList] = useState(Default.characters)
  const [charBGIndex, setCharBGIndex] = useState({ cat: "Champions", index: 3 })
  const [settingPage, setSettingPage] = useState("puyo")

  const [colorEditData, setColorEditData] = useState(colors)

  const reloadPressed = useRef(false)

  const canvas = useRef()
  const context = useRef()
  const test = useRef(null)

  useEffect(() => {
    import("localforage")
      .then(module => {
        localForage = module.default
      })
      .then(() => {
        Promise.all([
          localForage.getItem("puyoSkin"),
          localForage.getItem("puyoSkinIndex"),
          localForage.getItem("charBG"),
          localForage.getItem("charBGIndex"),
          localForage.getItem("charName"),
          localForage.getItem("puyoSkinList"),
          localForage.getItem("charBGList"),
        ]).then(values => {
          // setPuyoSkinList({ ...puyoSkinList, ...userSkins })
          setCharBGList({ ...charBGList, userCharacters })
          // if (values[0]) setPuyoSkin(values[0])
          // console.log(values[0]) // puyo skin data url?
          if (values[1]) setPuyoSkinIndex(values[1])
          if (values[2]) setCharBG(values[2])
          if (values[3]) setCharBGIndex(values[3])
          if (values[4]) setCharName(values[4])
          if (values[5]) {
            setPuyoSkinList(values[5])

            let skinName = Object.keys(values[5])[values[1]]
            console.log(values[5][skinName].settings)
            setColorEditData(values[5][skinName].settings)
          }

          if (values[6]) setCharBGList(values[6])
        })
      })

    // Load canvas object
    canvas.current = document.createElement("canvas")
    canvas.current.width = 2048
    canvas.current.height = 1024
    context.current = canvas.current.getContext("2d")
    // console.log(context.current)
  }, [])

  const saveSettings = () => {
    // References to refs
    const cvs = canvas.current
    const ctx = context.current

    // Function to test if the current skin is using the default settings
    const checkIfDefaultSettings = () => {
      for (let color in colorEditData) {
        if (colorEditData[color].hue !== 0) return false
        if (colorEditData[color].sat !== 100) return false
        if (colorEditData[color].bri !== 100) return false
        if (colorEditData[color].con !== 100) return false
      }
      return true
    }

    // Get the name of the current skin
    const skinName = Object.keys(puyoSkinList)[puyoSkinIndex]

    let newPuyoSkin
    const newPuyoSkinList = JSON.parse(JSON.stringify(puyoSkinList))
    // console.log(`Using default settings?: ${checkIfDefaultSettings()}`)
    if (checkIfDefaultSettings()) {
      // console.log("Applying defaults")
      newPuyoSkinList[skinName].useCustomUrl = false

      Promise.all([
        localForage.setItem("puyoSkin", newPuyoSkinList[skinName].url),
        localForage.setItem("puyoSkinIndex", puyoSkinIndex),
        localForage.setItem("charBG", charBG),
        localForage.setItem("charBGIndex", charBGIndex),
        localForage.setItem("charName", charName),
        localForage.setItem("puyoSkinList", newPuyoSkinList),
        localForage.setItem("charBGList", charBGList),
      ]).then(() => {
        window.location.reload()
      })
    } else {
      // Clear the canvas of anything currently on it.
      ctx.clearRect(0, 0, cvs.width, cvs.height)

      const img = new Image()
      img.onload = function() {
        ctx.drawImage(this, 0, 0, 2048, 1024, 0, 0, 2048, 1024)
        ctx.clearRect(0, 0, 1152, 360) // Clear colored Puyos
        ctx.clearRect(1296, 72, 72, 72) // Clear garbage puyo

        colorList.forEach((color, i) => {
          ctx.filter = `none`
          ctx.filter = `hue-rotate(${colorEditData[color].hue}deg) saturate(${
            colorEditData[color].sat
          }%) brightness(${colorEditData[color].bri}%) contrast(${
            colorEditData[color].con
          }%)`
          if (
            color === "Garbage" &&
            (colorEditData["Garbage"].hue !== 0 ||
              colorEditData["Garbage"].sat !== 100 ||
              colorEditData["Garbage"].bri !== 100 ||
              colorEditData["Garbage"].con !== 100)
          ) {
            ctx.filter = `sepia(100%) ` + ctx.filter
          }

          if (i < 5) {
            ctx.drawImage(this, 0, i * 72, 1152, 72, 0, i * 72, 1152, 72)
          } else {
            ctx.drawImage(this, 1296, 72, 72, 72, 1296, 72, 72, 72)
          }
        })

        // Get data URL
        newPuyoSkin = cvs.toDataURL("image/png")
        // console.log(newPuyoSkin)
        // Replace color settings
        // console.log(skinName)
        // console.log(colorEditData)
        newPuyoSkinList[skinName].customUrl = newPuyoSkin
        newPuyoSkinList[skinName].useCustomUrl = true
        newPuyoSkinList[skinName].settings = { ...colorEditData }

        // Save info to IndexedDB
        Promise.all([
          localForage.setItem("puyoSkin", newPuyoSkin),
          localForage.setItem("puyoSkinIndex", puyoSkinIndex),
          localForage.setItem("charBG", charBG),
          localForage.setItem("charBGIndex", charBGIndex),
          localForage.setItem("charName", charName),
          localForage.setItem("puyoSkinList", newPuyoSkinList),
          localForage.setItem("charBGList", charBGList),
        ]).then(() => {
          window.location.reload()
        })
      }
      img.src = newPuyoSkinList[skinName].url
    }
  }

  return (
    <Container fluid className="mt-2" ref={test}>
      <Button
        style={{ width: `100%` }}
        onClick={() => {
          if (!reloadPressed.current) {
            saveSettings()
            reloadPressed.current = true
          } else {
            // console.log("Already trying to generate an image. Please wait...")
          }
        }}
      >
        Save Settings and Reload
      </Button>
      <Card className="mt-2">
        <Card.Header>
          <Nav
            variant="tabs"
            defaultActiveKey="puyo"
            onSelect={key => setSettingPage(key)}
          >
            <Nav.Item>
              <Nav.Link eventKey="puyo">Puyo Skins</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="character">Character</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="background">Background</Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        {settingPage === "puyo" ? (
          <>
            <Card.Body>
              <Card.Title>
                <h4>
                  Selected:{" "}
                  <Badge variant="secondary">
                    {Object.keys(puyoSkinList)[puyoSkinIndex]}
                  </Badge>
                </h4>
              </Card.Title>
              <PuyoSkinPicker
                puyoSkinList={puyoSkinList}
                puyoSkinIndex={puyoSkinIndex}
                // setPuyoSkin={setPuyoSkin}
                setPuyoSkinIndex={setPuyoSkinIndex}
              />
              <Card.Text />
            </Card.Body>
            <PuyoColorEdit
              imgsrc={
                puyoSkinList[Object.keys(puyoSkinList)[puyoSkinIndex]].url
              }
              colorEditData={colorEditData}
              setColorEditData={setColorEditData}
              className="mt-2"
            />
          </>
        ) : settingPage === "character" ? (
          <Card.Body>
            <div>Character: {charName}</div>
            <CharPicker
              charBGList={charBGList}
              charBGIndex={charBGIndex}
              setCharName={setCharName}
              setCharBG={setCharBG}
              setCharBGIndex={setCharBGIndex}
            />
          </Card.Body>
        ) : settingPage === "background" ? (
          <Card.Body>
            <BackgroundPicker setBackground={setBackground} />
          </Card.Body>
        ) : (
          <Card.Body>
            <Card.Text>No item selected.</Card.Text>
          </Card.Body>
        )}
      </Card>
    </Container>
  )
}

export default TabGraphicsSettings
