import React, { useState, useEffect } from "react"
import { Form, Row, Col } from "react-bootstrap"
import chainTags from "./chainTags"
const toggleTagDict = bool => {
  const obj = {}
  chainTags.forEach(tag => {
    obj[tag] = bool
  })
  return obj
}
// console.log(toggleTagDict(false))

const TabCreateDrill = ({ gameMounted, updateHistory }) => {
  const [tagsToggle, setTagsToggle] = useState(toggleTagDict(false))
  const [title, setTitle] = useState("")
  const [author, setAuthor] = useState("")
  const [category, setCategory] = useState("")
  const [description, setDescription] = useState("")
  const [outputFolder, setOutputFolder] = useState("")
  const [date, setDate] = useState(new Date())

  // Create list of chain tags to display
  const tagsMapped = Object.keys(tagsToggle).map((tag, i) => (
    <Form.Check
      inline
      label={tag}
      type="checkbox"
      key={tag}
      id={`tag-${i}-${tag}`}
      checked={tagsToggle[tag]}
      onChange={e => {
        const newTags = { ...tagsToggle }
        newTags[tag] = !newTags[tag]
        setTagsToggle(newTags)
      }}
    />
  ))

  useEffect(() => {
    if (gameMounted.current) {
      updateHistory({
        title,
        author,
        category,
        description,
        outputFolder,
        date,
        tagsToggle,
      })
      // console.log("Note to self: this effect might be overwriting imported data, later on.")
    }
  })

  return (
    <div>
      <Form>
        <Form.Group as={Row} controlId="title">
          <Col>
            <Form.Control
              type="text"
              placeholder="Title"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="author">
          <Col>
            <Form.Control
              type="text"
              placeholder="Author"
              value={author}
              onChange={e => setAuthor(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="category">
          <Col>
            <Form.Control
              type="text"
              placeholder="Category"
              value={category}
              onChange={e => setCategory(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="outputfolder">
          <Col>
            <Form.Control
              type="text"
              placeholder="Output Folder"
              value={outputFolder}
              onChange={e => setOutputFolder(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="date">
          <Col>
            <Form.Control
              type="text"
              value={date}
              onChange={e => setDate(e.target.value)}
              placeholder="Date"
              readOnly
            />
          </Col>
        </Form.Group>
      </Form>

      <h3>Slide Info</h3>
      <Form>
        <Form.Group controlId="slideInfo">
          <Form.Control
            as="textarea"
            rows="4"
            placeholder={"Give an overall description for the chain drill."}
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </Form.Group>
      </Form>

      <h3>Tags</h3>
      <div style={{ height: `300px`, overflow: `scroll` }}>
        <Form>{tagsMapped}</Form>
      </div>
    </div>
  )
}

export default TabCreateDrill
